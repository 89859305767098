import React, { useEffect, useRef, useState } from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Marker from './marker';
import ReactDOMServer from "react-dom/server";
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl';
import { useCourseLocationThumbnails } from '@queries/use-course-location-thumbnails';

const Map = (props) => {
  const breakpoints = useBreakpoint();
  const [customMap, setCustomMap] = useState(null);
  const { getThumbnailPath } = useCourseLocationThumbnails();
  const [accessToken] = useState(process.env.GATSBY_MABBOX_GL_TOKEN);
  let mapContainer = useRef(null);

  const mapConfig = {
    style: 'mapbox://styles/mapbox/light-v10',
    center: [Number(props.localization.coordinates.longitude), Number(props.localization.coordinates.latitude)],
    zoom: 14.8
  };

  useEffect(() => {
    if (accessToken) {
      mapboxgl.accessToken = accessToken;

      setCustomMap(new mapboxgl.Map({
        container: mapContainer,
        ...mapConfig,
        dragRotate: true, // disable map rotation using right click + drag
        touchZoomRotate: true, // disable map rotation using touch rotation gesture
      }));
    }
  }, []);

  useEffect(() => {
    if (customMap && breakpoints && Object.keys(breakpoints).length) {

      // Define marker
      const markerElement = document.createElement('div');
      markerElement.innerHTML = ReactDOMServer.renderToString(
        <Marker
          showTooltip={!breakpoints.mobile}
          thumbnailSrc={getThumbnailPath(props.localization.thumbnail)}
          title={props.localization.title}
          phoneNumber={props.localization.phoneNumber}
          address={props.localization.address}
        />
      );

      if (props.localization.googleMapLink) {
        markerElement.addEventListener('click', () => {
          const newWindow = window.open();
          if (newWindow) {
            newWindow.opener = null;
            newWindow.location.replace(props.localization.googleMapLink);
          }
        });
      }

      new mapboxgl.Marker(markerElement).setLngLat(mapConfig.center).addTo(customMap);
      breakpoints.mobile && customMap.dragPan.disable();
    }
  }, [customMap, breakpoints]);

  if (accessToken) {
    return (<div style={{ height: 450 }} ref={el => mapContainer = el}>{`${mapConfig}`}</div>);
  } else {
    return null;
  }
};

export default Map;

Map.defaultProps = {
  mapConfig: {},
  localization: {
    title: "",
    thumbnail: '',
    coordinates: {
      latitude: "",
      longitude: ""
    },
    address: {
      streetAddress: "",
      postalCode: "",
      city: ""
    },
    phoneNumber: "",
    googleMapLink: ""
  }
};

Map.propTypes = {
  mapConfig: PropTypes.shape({
    style: PropTypes.string,
    center: PropTypes.arrayOf(PropTypes.number),
    zoom: PropTypes.number,
  }),
  thumbnail: PropTypes.string,
  localization: PropTypes.shape({
    title: PropTypes.string,
    coordinates: PropTypes.shape({
      latitude: PropTypes.string,
      longitude: PropTypes.string,
    }),
    address: PropTypes.shape({
      streetAddress: PropTypes.string,
      postalCode: PropTypes.string,
      city: PropTypes.string,
    }),
    phoneNumber: PropTypes.string,
    googleMapLink: PropTypes.string,
  }),
};
