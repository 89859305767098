import React from 'react';
import Layout from '@components/domain/shared/layout';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { graphql } from "gatsby";
import SEO from '@components/ui/seo';
import Specs from '@components/domain/course-details/specs';
import Description from '@components/domain/course-details/description';
import SpecsDrawer from '@components/domain/course-details/specs-drawer';
import PeopleInvolved from '@components/domain/course-details/people-involved';
import SectionClosedCourses from '@components/domain/homepage/closed-courses';
import EmptySpace from '@components/ui/empty-space';
import Accordion from '@components/ui/accordion';
import styles from '@styles/templates/curso-detalhes.module.scss';
import PropTypes from 'prop-types';
import Breadcrumb from '@components/ui/breadcrumb';
import { Link } from 'gatsby';
import Map from '@components/ui/map';
import Spacing from '@components/ui/spacing';
import LazyLoad from 'react-lazyload';

// MapBox map is powered by "localization" section from JSON files
// It will only be displayed if at least the following criteria are met:
// - course format should NOT be "online"
// - an "title" should be supplied in JSON file
// - longitude and latitude coordinates should be supplied in JSON file

const shouldMapBeVisible = general => {
  return general.format.toLowerCase() !== 'online' &&
  general.localization.title &&
  general.localization.coordinates.longitude &&
  general.localization.coordinates.latitude;
};

const CursoDetalhes = (props) => {
  const breakpoints = useBreakpoint();
  const {
    data: { course },
    path,
  } = props;
  
  return (
    <Layout extraFooterPadding={breakpoints.mobile ? 200 : 0}>
      <SEO
        title={course.description.title}
        description={course.description.shortDescription[0]}
        image={{
          src: course.description.imageUrl.childImageSharp.fixed.src,
          height: course.description.imageUrl.childImageSharp.fixed.height,
          width: course.description.imageUrl.childImageSharp.fixed.width,
          name: course.description.imageUrl.name,
          mediaType: course.description.imageUrl.internal.mediaType,
        }}
      />
      <EmptySpace desktop={{ margin: 120 }} mobile={{ margin: 100 }} />

      <div className={styles.container}>

        <Spacing>
          <Breadcrumb className={styles.breadcrumb}>
            <Link to="/">página inicial</Link>
            <Link to="/cursos/">cursos</Link>
            <Link to={`${path}/`}>{String(course.description.title).toLowerCase()}</Link>
          </Breadcrumb>

          <div className={styles.metadata}>
          
            <section className={styles.overview}>

              <Description
                courseName={course.description.title}
                scholarity={course.description.scholarity}
                imageUrl={course.description.imageUrl}
                shortDescription={course.description.shortDescription}
                topics={course.description.topics}
                longDescription={course.description.longDescription}
                additionalInfo={course.description.additionalInfo}
              />

              <EmptySpace desktop={{ margin: 80, isLineVisible: false }} mobile={{ margin: 80, isLineVisible: false }} />


            </section>

            <section className={styles.specifics}>
              <Specs
                applicationsDates={course.general.applications}
                data={course.specifications.data}
                applicationPath={`${path}/candidatura`}
              />
            </section>
          </div>

        </Spacing>
        <LazyLoad height={200} >
          {
            course.faq &&
            <div className={styles.faqSection}>
              <Accordion allowMultiple={true}>
                <div label="Perguntas frequentes">
                  <div className={styles.faq}>
                    {
                      course.faq.map((item, index) => {
                        return (
                          <div key={`${index}-${item.title}`} className={styles.question}>
                            <h2>{item.title}</h2>
                            <p>{item.value}</p>
                          </div>
                        );
                      })
                    }
                  </div>
                </div>
              </Accordion>
            </div>
          }
        </LazyLoad>
      

        <EmptySpace desktop={{ margin: 80, isLineVisible: false }} mobile={{ isHidden: true }} />

        <LazyLoad height={1000} once >
          <PeopleInvolved peopleInvolved={course.peopleInvolved} />
        </LazyLoad>

        {
          shouldMapBeVisible(course.general) &&
          <LazyLoad height={450} >
            <Map localization={course.general.localization} />
          </LazyLoad>
        }

        <div className={styles.courseList}>
          <LazyLoad height={800} once >
            <SectionClosedCourses />
          </LazyLoad>
        </div>
      </div>
  
      <EmptySpace desktop={{ margin: 80, isLineVisible: false }} mobile={{ isHidden: true }} />
  
      <section className={styles.specificsDrawer}>
        <SpecsDrawer
          applicationPath={`${path}/candidatura`}
          applicationsDates={course.general.applications}
          data={course.specifications.data}
        />
      </section>
      
    </Layout>
  );
};

export default CursoDetalhes;

export const pageQuery = graphql`
  query CourseDetailsQuery($jsonFileId: String) {
    course: coursesJson(id: {eq: $jsonFileId}) {
      id
      faq {
        title
        value
      }
      general {
        applications {
          endDate
          startDate
        }
        subscriptionLink
        format
        localization {
          title
          thumbnail
          coordinates {
            latitude
            longitude
          }
          address {
            streetAddress
            postalCode
            city
          }
          phoneNumber
          googleMapLink
        }
      }
      description {
        title
        imageUrl {
          publicURL
          name
          internal {
            mediaType
          }
          childImageSharp {
            fixed {
              src
              width
              height
            }
          }
        }
        longDescription
        additionalInfo
        scholarity
        shortDescription
        topics
      }
      peopleInvolved {
        username
        excerpt
        group
        avatarImage {
          publicURL
          name
        }
      }
      specifications {
        data {
          value
          key
        }
      }
    }
  }
`;

CursoDetalhes.propTypes = {
  path: PropTypes.string,
  data: PropTypes.shape({
    course: PropTypes.shape({
      description: PropTypes.shape({
        title: PropTypes.string,
        scholarity: PropTypes.string,
        imageUrl: PropTypes.shape({
          name: PropTypes.string,
          publicURL: PropTypes.string,
          internal: PropTypes.shape({
            mediaType: PropTypes.string,
          }),
          childImageSharp: PropTypes.shape({
            fixed: PropTypes.shape({
              src: PropTypes.string,
              height: PropTypes.number,
              width: PropTypes.number,
            })
          })
        }),
        shortDescription: PropTypes.arrayOf(PropTypes.string),
        topics: PropTypes.arrayOf(PropTypes.string),
        longDescription: PropTypes.arrayOf(PropTypes.string),
        additionalInfo: PropTypes.arrayOf(PropTypes.string),
      }),
      general: PropTypes.shape({
        applications: PropTypes.shape({
          endDate: PropTypes.string,
        }),
        subscriptionLink: PropTypes.string,
        format: PropTypes.string,
        localization: PropTypes.shape({
          title: PropTypes.string,
          thumbnail: PropTypes.string,
          coordinates: PropTypes.shape({
            latitude: PropTypes.string,
            longitude: PropTypes.string,
          }),
          address: PropTypes.shape({
            streetAddress: PropTypes.string,
            postalCode: PropTypes.string,
            city: PropTypes.string,
          }),
          phoneNumber: PropTypes.string,
          googleMapLink: PropTypes.string,
        }),
      }),
      faq: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        value: PropTypes.string,
      })),
      specifications: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({
          key: PropTypes.string,
          values: PropTypes.string,
        })),
      }),
      peopleInvolved: PropTypes.arrayOf(PropTypes.shape({
        username: PropTypes.string,
        excerpt: PropTypes.string,
        group: PropTypes.arrayOf(PropTypes.string),
        avatarImage: PropTypes.shape({
          name: PropTypes.string,
          publicURL: PropTypes.string,
        })
      }))
    })
  })
};
