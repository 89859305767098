import React from 'react';
import PropTypes from 'prop-types';
import styles from './grey-space.module.scss';

const GreySpace = (props) => {

  return (
    <div className={styles.container}>
      {props.children}
    </div>
  );
};

export default GreySpace;

GreySpace.propTypes = {
  children: PropTypes.node,
};
