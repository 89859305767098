import React from 'react';
import PropTypes from 'prop-types';
import styles from './marker.module.scss';

const yellowCircleStyle = {
  opacity: 0.5,
  height: 92,
  width:  92,
  backgroundColor: '#feee00',
  borderRadius: '50%',
  display: 'inline-block',
};

const join = (separator, items) => {
  const filtered = items.filter(Boolean);
  if (filtered.length === 1) {
    return filtered.join('');
  }

  return filtered.join(separator);
};

const renderLabel = props => (
  <div className={styles.tooltip}>
    {props.thumbnailSrc && <img src={props.thumbnailSrc} style={{ height: 40 }} alt={props.title} />}

    <div className={styles.content}>
      {props.title && <h2 className={styles.title}>{props.title}</h2>}
      {props.address.streetAddress && <p className={styles.subtitle}>{props.address.streetAddress}</p>}
      {
        (props.address.postalCode ||
        props.address.city) &&
        <p className={styles.subtitle}>{join(', ', [props.address.postalCode, props.address.city])}</p>
      }
      {props.phoneNumber && <p className={styles.phoneNumber}>{props.phoneNumber}</p>}
      <span className={styles.leftArrow}></span>
    </div>
  </div>
);

const Marker = (props) => {
  return (
    <div style={{ display: 'inline-block', position: 'relative' }}>
      <span style={{ ...yellowCircleStyle }}></span>
      {props.showTooltip && renderLabel(props)}
    </div>
  );
};

export default Marker;

Marker.defaultProps = {
  showTooltip: true,
  thumbnailSrc: '',
  title: "",
  phoneNumber: "",
  address: {
    streetAddress: "",
    postalCode: "",
    city: ""
  },
};

Marker.propTypes = {
  showTooltip: PropTypes.bool,
  thumbnailSrc: PropTypes.string,
  title: PropTypes.string,
  phoneNumber: PropTypes.string,
  address: PropTypes.shape({
    streetAddress: PropTypes.string,
    postalCode: PropTypes.string,
    city: PropTypes.string,
  }),
};
