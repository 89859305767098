import React from 'react';
import PropTypes from 'prop-types';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Metadata from '@components/domain/course-details/metadata';
import ActionLink from '@components/ui/action-link';
import { getEndDateLabel, isSignUpOpen } from '@utilities/course-data-helpers';
import "@fortawesome/fontawesome-svg-core/styles.css";
import styles from './specs.module.scss';
config.autoAddCss = false;

const renderHeader = (applicationsDates, applicationPath) => (
  <React.Fragment>
    <ActionLink
      path={applicationPath}
      label="candidata-te a este curso"
      width="100%"
    />
    <div className={styles.closingDate}>
        <FontAwesomeIcon color="#da0000" icon={faClock} size="1x" />
        <p>
          {getEndDateLabel(applicationsDates.endDate)}
        </p>
      </div>
  </React.Fragment>
);

const Specs = (props) => (
  <div className={styles.container}>
    {
      props.applicationsDates &&
      isSignUpOpen(props.applicationsDates) &&
      renderHeader(props.applicationsDates, props.applicationPath)
    }

    <div className={styles.metadata}>
      <Metadata data={props.data} maxWidth="164px" />
    </div>
  </div>
);

export default Specs;

Specs.defaultProps = {
  applicationsDates: null,
  data: [],
  applicationPath: "",
};

Specs.propTypes = {
  applicationsDates: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
  data: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  applicationPath: PropTypes.string,
};
