import React from 'react';
import ProfileCard from '@components/ui/profile-card';
import PropTypes from 'prop-types';
import GreySpace from '@components/ui/grey-area';
import styles from './people-involved.module.scss';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Accordion from '@components/ui/accordion';

const generateProfiles = data => {
  return (
    data.map(item => {
      return (
        <div key={item.username} className={styles.profileCard}>
          <ProfileCard
            name={item.username}
            profession={item.excerpt}
            imageUrl={item.avatarImage}
            desktop={{ size: 160 }}
          />
        </div>
      );
    })
  );
};

const generateMobileGroup = (groupName, people) => {
  const sectionStyle = {
    marginTop: '13px',
    marginLeft: '15px',
    marginRight: '15px',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(158px, 1fr))',
    gridColumnGap: '30px',
    gridRowGap: '30px',
    justifyItems: 'center'
  };
  
  return (
    <div key={groupName} label={groupName}>
      <div style={{ ...sectionStyle }}>
        {generateProfiles(people)}
      </div>
    </div>
  );
};

const generateDesktopGroup = (groupName, people) => {
  const sectionStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(160px, 1fr))',
    gridColumnGap: '30px',
    gridRowGap: '30px',
  };

  return (
    <section key={groupName} className={styles.creators}>
      <h1>{groupName}</h1>

      <div style={{ ...sectionStyle }}>
        {generateProfiles(people)}
      </div>

    </section>
  );
};

const PeopleInvolved = (props) => {
  const breakpoints = useBreakpoint();

  if (props.peopleInvolved && props.peopleInvolved.length === 0) {
    return null;
  }

  const allGroups = [...props.peopleInvolved].flatMap(person => person.group);
  const uniqueGroups = [... new Set(allGroups)];
  
  if (uniqueGroups.length === 0) {
    return null;
  }

  if (breakpoints.mobile) {
    return (
      <div className={styles.mobileContainer}>
        <Accordion allowMultiple={true}>
        {
          uniqueGroups.map(groupName => {
            const people = props.peopleInvolved.filter(person => person.group.includes(groupName));
            return generateMobileGroup(groupName, people);
          })
        }
        </Accordion>
      </div>
    );

  } else {
    const containerStyle = breakpoints.tablet ? { margin: '16px' } : { margin: '140px 165px' };
    return (
      <GreySpace>
        <div className={styles.container} style={{ ...containerStyle }}>

          {
            uniqueGroups.map(groupName => {
              const people = props.peopleInvolved.filter(person => person.group.includes(groupName));
              return generateDesktopGroup(groupName, people);
            })
          }

        </div>
      </GreySpace>
    );
  }
};

export default PeopleInvolved;

PeopleInvolved.propTypes = {
  peopleInvolved: PropTypes.arrayOf(PropTypes.shape({
    username: PropTypes.string,
    excerpt: PropTypes.string,
    group: PropTypes.arrayOf(PropTypes.string),
    avatarImage: PropTypes.shape({
      name: PropTypes.string,
      publicURL: PropTypes.string,
    })
  }))
};
