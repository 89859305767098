import { useStaticQuery, graphql } from 'gatsby';

export const useCourseLocationThumbnails = () => {
  const data = useStaticQuery(
    graphql`
      query CourseLocationsMetaData {
        allFile(filter: {sourceInstanceName: {eq: "images"}, relativeDirectory: {eq: "course-locations"}}) {
          edges {
            node {
              name
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    `
  );

  // Received the filename (string) of an image (extension is omitted)
  // Searches in folder "course-locations" for that specific image file
  // Returns path to the thumbnail, if any
  const getThumbnailPath = filename => {
    if (data && data.allFile && data.allFile.edges.length === 0) {
      return '';
    }
  
    if (filename != undefined && filename !== '') {
      const edge = data.allFile.edges.find(edge => edge.node.name === filename);
      if (edge) {
        return edge.node.childImageSharp.fluid.src;
      } else {
        return '';
      }
    }
  
    return '';
  };

  return {
    getThumbnailPath
  };
};