import React from 'react';
import PropTypes from 'prop-types';
import Spacing from '@components/ui/spacing';
import EmptySpace from '@components/ui/empty-space';
import ActionLink from '@components/ui/action-link';
import Metadata from '@components/domain/course-details/metadata';
import SwipeableSheet from '@components/ui/swipeable-sheet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { config } from '@fortawesome/fontawesome-svg-core';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { getEndDateLabel, isSignUpOpen } from '@utilities/course-data-helpers';
import styles from './specs-drawer.module.scss';
import "@fortawesome/fontawesome-svg-core/styles.css";

config.autoAddCss = false;

const brownishGrey = '#6b6b6b';
const veryLightPink = '#f1f1f1';

// Is displayed at the top of the drawer
const getChevronIcon = isOpen => (
  <FontAwesomeIcon
    color={brownishGrey}
    icon={isOpen ? faChevronDown : faChevronUp}
    size="2x"
  />
);

const drawerFooter = (applicationsDates, applicationPath) => (
  <div className={styles.fixedFooter}>
    <Spacing>
      <div className={styles.footerContent}>
        <ActionLink
          path={applicationPath}
          label="candidata-te a este curso"
          width="100%"
        />

        <div className={styles.closingDate}>
          {
            applicationsDates && applicationsDates.endDate &&
            <React.Fragment>
              <FontAwesomeIcon color="#da0000" icon={faClock} size="sm" />
              <p>{getEndDateLabel(applicationsDates.endDate)}</p>
            </React.Fragment>
          }
        </div>
      </div>
    </Spacing>
  </div>
);

// Drawer has hight variation based on the size of the screen
// If it's a tall screen, maximum height is 568 pixels
// Otherwise, the height is calculated based on percentage
// 70% for portrait mode and 45% for landscape mode
const getDrawerHeight = isLandscape => {
  const maxHeight = 568;
  const maxPercentage = isLandscape ? 45 : 70;

  const heighBasedOnPercentage = ((window.innerHeight * maxPercentage) / 100) || 0;
  const finalHeigh = heighBasedOnPercentage > maxHeight ? maxHeight : heighBasedOnPercentage;

  return finalHeigh;
};

const SpecsDrawer = (props) => {
  const breakpoints = useBreakpoint();
  const [isDrawerOpen, setDrawerOpen] = React.useState(false);
  const [drawerHeight, setDrawerHeight] = React.useState();
  const overflowHeight = 55; // offset because of fixed footer
  const isCourseOpen = isSignUpOpen(props.applicationsDates);
  

  // Whenever the drawer is open, we don't want to allow main content to scroll
  React.useEffect(() => {
    if (isDrawerOpen) {
      disableBodyScroll(document.querySelector('body'));
    } else {
      enableBodyScroll(document.querySelector('body'));
    }

    // some mobile phones have very wide landscape
    // thus, the drawer is not rendered, but we need to close it and unlock the scrolling
    if (breakpoints.landscape && !breakpoints.mobile) {
      setDrawerOpen(false);
      enableBodyScroll(document.querySelector('body'));
    }

    setDrawerHeight(getDrawerHeight(breakpoints.landscape));

    return () => {
      enableBodyScroll(document.querySelector('body'));
    };

  }, [isDrawerOpen, breakpoints.landscape]);

  return (
    <div className={styles.container}>

      <SwipeableSheet
        onChange={state => setDrawerOpen(state)} overflowHeight={overflowHeight} bodyStyle={{ backgroundColor: '#f1f1f1' }}
        open={isDrawerOpen}
        style={{
          borderRadius: 20,
          bottom: isCourseOpen ? (breakpoints.landscape ? 80 : 100) : 0,
        }}
      >
        <div style={{ height: drawerHeight, backgroundColor: veryLightPink }}>
          <Spacing>
            <div className={styles.chevronIconStatus}>
              {getChevronIcon(isDrawerOpen)}
            </div>
            <Metadata data={props.data} />
            <EmptySpace desktop={{ isHidden: true }} mobile={{ margin: 80, isLineVisible: false }} />
          </Spacing>
        </div>
      </SwipeableSheet>

      {isCourseOpen && drawerFooter(props.applicationsDates, props.applicationPath)}
    </div>
  );
};

export default SpecsDrawer;

SpecsDrawer.defaultProps = {
  applicationsDates: null,
  data: [],
  applicationPath: "",
};

SpecsDrawer.propTypes = {
  applicationsDates: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
  data: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  applicationPath: PropTypes.string,
};
