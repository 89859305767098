import React from 'react';
import PropTypes from 'prop-types';
import styles from './breadcrumb.module.scss';

const Breadcrumb = (props) => {
  const children = React.Children.toArray(props.children);

  return (
    <ul className={`${styles.breadcrumb} ${props.className}`}>
      {children.map((child, index) => (
          <li key={`${child.props.to}-${index}`}>
            {child}
          </li>
        ))}
    </ul>
  );
};

export default Breadcrumb;

Breadcrumb.defaultProps = {
  className: '',
};

Breadcrumb.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

