import React from 'react';
import PropTypes from 'prop-types';
import styles from './metadata.module.scss';

const Metadata = (props) => (
  <div className={styles.container}>
    {
      props.data &&
      props.data.map((row, index) => {
        return (
          <div key={`${index}-${row.key}`} className={styles.row}>
            <div className={styles.key} style={{ maxWidth: props.maxWidth }}>{row.key}</div>
            <div className={styles.value} style={{ maxWidth: props.maxWidth }}>{row.value}</div>
          </div>
        );
      })
    }
  </div>
);

export default Metadata;

Metadata.defaultProps = {
  maxWidth: '100%',
  data: [],
};

Metadata.propTypes = {
  maxWidth: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
};
